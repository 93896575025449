import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import  classNames  from 'classnames';
// import { menuItems } from './MenuData';
import { useSelector, useDispatch } from 'react-redux';
import { setSideBarItems } from '../../../actions/sideBarActions'

import { _trans } from '../../../actions/locoBOActions';

export default function MenuItems(){

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO);

    const { user } = useSelector(state => state.login);

    const location = useSelector(state => state.location);
    const dispatch = useDispatch();

    let menuItems = [];

    const { postType,
        loading,
        error } = useSelector(state => state.postTypeList);
    const { sideBarItems} = useSelector(state => state.sideBarItems);

    const { loading: userAuthPermissionsLoading, userAuthPermissions } = useSelector(state => state.userAuthPermissions);
    
    const hasPermissions =(key)=>{
        return userAuthPermissions?.find((item)=>{
           return item.key === key || item.key === "ALL"
        })
    }

    useEffect(() => {
        let menuItemsArray = [];

        let menuItemsObject = {};

        //Reports
        menuItemsObject = {
                icon: 'insert_chart',
                label: _trans('Reports',locosBO),
                to: '/',
                key: 'DASHBOARD',
            }
        menuItemsArray.push(menuItemsObject);

        //reservations
        menuItemsObject = {
                icon: 'group',
                label: _trans('Reservations',locosBO),
                to: '/reservations',
                key: 'RESERVATIONS'
            }
        menuItemsArray.push(menuItemsObject);

        //Users
        if(user.token.authUser.roles_name == "SuperAdmin"){
            menuItemsObject = {
                icon: 'people',
                label: _trans('Users',locosBO),
                to: '#',
                key: 'USERS_ROOT',
                content: [{
                        icon: 'face',
                        label: _trans('Users',locosBO),
                        to: '/users',
                        key: 'USERS'
                    },
                    {
                        icon: 'verified_user',
                        label: _trans('Roles',locosBO),
                        to: '/roles',
                        key: 'ROLES'
                    },
                    {
                        icon: 'tune',
                        label: _trans('Permissions',locosBO),
                        to: '/permissions',
                        key: 'PERMISSIONS'
                    }

                ],
            }
            menuItemsArray.push(menuItemsObject);

        }else{
            menuItemsObject = {
                icon: 'face',
                label: _trans('Users',locosBO),
                to: '/users',
                key: 'USERS'
            }
            menuItemsArray.push(menuItemsObject);
        }
        
        //Categories
        if(user.token.authUser.roles_name == "SuperAdmin"){
            menuItemsObject = {
                    icon: 'view_list',
                    label: _trans('Categories',locosBO),
                    to: '#',
                    key: 'CATEGORIES',
                    content: [{
                            icon: 'playlist_add',
                            label: _trans('Categories',locosBO),
                            to: '/categories',
                            key: 'CATEGORIES'
                        },
                        {
                            icon: 'playlist_add',
                            label: _trans('Categories Items',locosBO),
                            to: '/category-items',
                            key: 'CATEGORIES_ITEMS'
                        }
                    ],
                }
            menuItemsArray.push(menuItemsObject);
        }
        //File manager
        menuItemsObject = {
                icon: 'perm_media',
                label: _trans('File manager',locosBO),
                to: '/files-manager',
                key: 'FILE_MANAGER'
            }
        menuItemsArray.push(menuItemsObject);

        //Post type
        if(user.token.authUser.roles_name == "SuperAdmin"){
            menuItemsObject = {
                    icon: 'art_track',
                    label: _trans('Post type',locosBO),
                    to: '/post-type'
                }
            menuItemsArray.push(menuItemsObject);
        }

        //Post Templates
        if(user.token.authUser.roles_name == "SuperAdmin"){
            menuItemsObject = {
                    icon: 'view_compact',
                    label: _trans('Post Templates',locosBO),
                    to: '/post-templates',
                    key: 'POST_TEMPLATES'
                }
            menuItemsArray.push(menuItemsObject);
        }

        //Menus
        if(user.token.authUser.roles_name == "SuperAdmin"){
            menuItemsObject = {
                    icon: 'menu',
                    label: _trans('Menus',locosBO),
                    to: '#',
                    key: 'MENUS_ROOT',
                    content: [{
                            icon: 'featured_play_list',
                            label: _trans('Menus List',locosBO),
                            to: '/menus',
                            key: 'MENUS'
                        },
                        {
                            icon: 'format_list_bulleted',
                            label: _trans('Menu items',locosBO),
                            to: '/menu-items',
                            key: 'MENU_ITEMS'
                        }
                    ],
                }
            menuItemsArray.push(menuItemsObject);
        }

        //Translation
        if(user.token.authUser.roles_name == "SuperAdmin"){
            menuItemsObject = {
                    icon: 'translate',
                    label: _trans('Translation',locosBO),
                    to: '#',
                    key: 'LANGUAGES_ROOT',
                    content: [{
                            icon: 'language',
                            label: _trans('Languages',locosBO),
                            to: '/languages',
                            key: 'LANGUAGES'
                        },
                        {
                            icon: 'chrome_reader_mode',
                            label: _trans('Loco',locosBO),
                            to: '/loco-translate',
                            key: 'LOCO_TRANSLATE'
                        },
                        {
                            icon: 'chrome_reader_mode',
                            label: _trans('Loco BO',locosBO),
                            to: '/locobo-translate',
                            key: 'LOCOBO_TRANSLATE'
                        }
                    ],
                }
            menuItemsArray.push(menuItemsObject);
        }

        //Authorized apps
        if(user.token.authUser.roles_name == "SuperAdmin"){
            menuItemsObject = {
                    icon: 'security',
                    label: _trans('Authorized apps',locosBO),
                    to: '/authorized-apps',
                    key: 'API'
                }
            menuItemsArray.push(menuItemsObject);
        }

        if(postType){

            postType.map((item,i)=>{

                let menuPostTypeItem = {};

                if(item.id == 7){
                    menuPostTypeItem.icon = 'home';
                }else{
                    menuPostTypeItem.icon = 'filter_none';
                }
                
                menuPostTypeItem.label = item.post_type_title;
                menuPostTypeItem.to = '/posts/'+ item.id;
                menuPostTypeItem.key = 'POST_'+ item.id;

                menuItemsArray.splice(3, 0, menuPostTypeItem);

            });

           
            dispatch(setSideBarItems(menuItemsArray))
            window.initPlugins();

        }

    }, []);
    
    return (
        <React.Fragment>
            
        <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">
            {
                sideBarItems.map((item,i)=>{
                    var parentClass = classNames('waves-effect waves-cyan', {
                        'collapsible-header': item.content,
                        'active': location.path === item.to
                    });
                    return hasPermissions(item.key) ?  (
                        <li className="bold" key={'menu-parent'+i}>
                            <Link to={item.to} className={parentClass}>
                                <i className="material-icons">{item.icon}</i>
                                <span className="menu-title" data-i18n="Mail">{item.label}</span>
                            </Link>
                            {item.content ? (
                                <div className="collapsible-body">
                                    <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                        {
                                            item.content.map((contentItem,i)=>{
                                                return hasPermissions(contentItem.key)  ? (
                                                        <li key={'menu-child' + i} className={location.path === contentItem.to ? ("active") : ""}>
                                                            <Link to={contentItem.to} className={location.path === contentItem.to ? ("active") : ""}>
                                                                <i className="material-icons"  >{contentItem.icon}</i>
                                                                <span data-i18n="Modern Menu">{contentItem.label}</span></Link>
                                                        </li>
                                                ) : ""
                                               
                                            }) 
                                        }

                                    </ul>
                                </div>
                            ) : ""}
                        </li>
                    ) : ""
                })
            }
        </ul>
        </React.Fragment>
    );
}
