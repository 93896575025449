export const RESERVATIONS_LIST_REQUEST = 'RESERVATIONS_LIST_REQUEST';
export const RESERVATIONS_LIST_SUCCESS = 'RESERVATIONS_LIST_SUCCESS'; 
export const RESERVATIONS_LIST_FAIL = 'RESERVATIONS_LIST_FAIL';
export const RESERVATIONS_SAVE_REQUEST = 'RESERVATIONS_SAVE_REQUEST';
export const RESERVATIONS_SAVE_SUCCESS = 'RESERVATIONS_SAVE_SUCCESS';
export const RESERVATIONS_SAVE_FAIL = 'RESERVATIONS_SAVE_FAIL'; 
export const RESERVATIONS_DELETE_REQUEST = 'RESERVATIONS_DELETE_REQUEST';
export const RESERVATIONS_DELETE_SUCCESS = 'RESERVATIONS_DELETE_SUCCESS';
export const RESERVATIONS_DELETE_FAIL = 'RESERVATIONS_DELETE_FAIL';
export const SELECTED_RESERVATION = 'SELECTED_RESERVATION';
export const RESERVATIONS_SIDEBAR_IS_TOGGLED = 'RESERVATIONS_SIDEBAR_IS_TOGGLED';