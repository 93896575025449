import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listPosts, deletePosts, selectedPost, restorePost, destroyPost } from '../../../actions/postsActions';
import Swal from 'sweetalert2';
import useHasPermission from '../../../hooks/useHasPermission'

import { _trans } from '../../../actions/locoBOActions';

export default function PostsTypeDatatable(props) {

    const { locosBO, loading: locosBOLoading, error: locosBOError } = useSelector(state => state.listLocosBO)

    const dispatch = useDispatch();

    const history = useHistory();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const postsList = useSelector(state => state.postsList);
    const { posts,
        loading,
        error } = postsList;

    const postsDelete = useSelector(state => state.postsDelete);
    const { loading: loadingDelete,
        error: errorDelete } = postsDelete;
    let post_type_id = props.match.params.id;
    const postsSave = useSelector(state => state.postsSave);
    const canUpdate = useHasPermission("/posts/" + post_type_id, "update");
    const canDelete = useHasPermission("/posts/" + post_type_id, "delete");
    const {
        success: successSave,
    } = postsSave;


    //get field ACF
    const get_field = (field_name, data) => {

        if (field_name && data) {
            let globalUrl = "https://api.villanium.com/"

            let fields = null;

            if (Array.isArray(data)) {
                fields = data;
            } else {
                fields = JSON.parse(JSON.parse(data.fields));
            }

            field_name = field_name.split(".");

            let selected_field = null;

            field_name.map(e => {

                if (!selected_field) {
                    selected_field = fields.find(element => element.name_input == e)
                } else {
                    if (Array.isArray(selected_field.fields) && selected_field.fields.length) {
                        selected_field = selected_field.fields[0].find(element => element.name_input == e)
                    }
                }

            });


            if (selected_field && selected_field.type_input == "Radio") {
                return selected_field.options;
            } else if (selected_field && selected_field.type_input == "CheckBox") {
                return selected_field.options;
            } else if (selected_field && selected_field.type_input == "Image") {
                return globalUrl + "/" + selected_field.value
            } else if (selected_field && selected_field.type_input == "Repeteur") {
                return selected_field.fields;
            } else if (selected_field && selected_field.type_input == "ImageGallery") {
                return selected_field.value.length ? selected_field.value.map((img) => {
                    return {
                        ...img, full_path: globalUrl + "/" + img.path
                    }
                }) : []
            }

            return selected_field?.value ? selected_field.value?.replace(/&nbsp;/g, ' ') : "";

        }
    }


    let columns = [
        {
            name: _trans('Name', locosBO),
            selector: 'title',
            sortable: true,
        },
        {
            name: 'Slug',
            selector: 'slug',
            sortable: true,
        },
        {
            cell: (post) => !post.deleted_at && canUpdate ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editPostsHandler(post); }}>
                <i className="material-icons">edit</i>
            </button> : post.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { destroyPostHandler(post); }}>
                <i className="material-icons" style={{ color: "#d32f2f" }}>delete_forever</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (posts) =>
                !posts.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deletePostsHandler(posts); }}>
                    <i className="material-icons">delete</i>
                </button> : posts.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { restorePostHandler(posts); }}>
                    <i className="material-icons">restore</i>
                </button> : undefined
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];

    if (post_type_id == 7) {
        columns = [
            {
                name: _trans('Image', locosBO),
                cell: (post) => <img src={get_field("property-image", post)} alt="" style={{ width: "100px", height: "80px", objectFit: "cover", objectPosition: "center" }} />
            },
            {
                name: _trans('Name', locosBO),
                selector: 'title',
                sortable: true,
            },
            {
                name: _trans('Type', locosBO),
                cell: (post) => <span>{get_field("property-type", post)}</span>
            },
            {
                name: _trans('Rooms', locosBO),
                cell: (post) => <span>{get_field("product-attributs.rooms", post)}</span>
            },
            {
                cell: (post) => !post.deleted_at && canUpdate ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editPostsHandler(post); }}>
                    <i className="material-icons">edit</i>
                </button> : post.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { destroyPostHandler(post); }}>
                    <i className="material-icons" style={{ color: "#d32f2f" }}>delete_forever</i>
                </button> : undefined,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: '50px'
            },
            {
                cell: (posts) =>
                    !posts.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deletePostsHandler(posts); }}>
                        <i className="material-icons">delete</i>
                    </button> : posts.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { restorePostHandler(posts); }}>
                        <i className="material-icons">restore</i>
                    </button> : undefined
                ,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: '50px'
            }
        ];
    }

    useEffect(() => {

        setFiltredData(posts);

        if (deleteSuccess) {
            setDeleteSuccess(false);
            dispatch(listPosts(props.postTypeId));
        }

    }, [successSave, deleteSuccess, posts]);

    const editPostsHandler = (postsData) => {
        dispatch(selectedPost(postsData));
        history.push('/posts/save/' + postsData.id_post_type + '/' + postsData.id);
    }

    const destroyPostHandler = (post) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            cancelButtonColor: '#43a047 ',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'supprimer définitivement'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(destroyPost(post, (response) => {
                    history.push('/posts/' + props.postTypeId)
                    dispatch(listPosts(props.postTypeId));
                    Swal.fire(
                        'supprimer définitivement',
                        'Post bien supprimer.',
                        'success'
                    )
                }));

            }
        })

    }


    const restorePostHandler = (post) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#43a047',
            cancelButtonColor: '#424242 ',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Restaurer'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(restorePost(post, (response) => {
                    history.push('/posts/' + props.postTypeId)
                    dispatch(listPosts(props.postTypeId));
                    Swal.fire(
                        'Restaurer',
                        'Post bien Restaurer.',
                        'success'
                    )
                }));

            }
        })

    }

    const deletePostsHandler = (postsData) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deletePosts(postsData, (response) => {
                    history.push('/posts/' + props.postTypeId)
                    setDeleteSuccess(true);
                    Swal.fire(
                        'Supprimer',
                        'Utilisateur bien supprimer.',
                        'success'
                    )
                }));

            }
        })
    }

    const dataFiltre = (e) => {
        let textFiltre = e.target.value;
        let data = posts.filter(item => item.title && item.title.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }

    return <React.Fragment>
        {loading || loadingDelete && <div></div>}
        {error && <div>{error}</div>}
        {errorDelete && <div>{errorDelete}</div>}
        <div className="content-area content-right">
            <div className="app-wrapper">
                <div className="datatable-search">
                    <i className="material-icons mr-2 search-icon">search</i>
                    <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                </div>
                <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
}

