import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik, FieldArray } from 'formik'

import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { listReservations, saveReservation, deleteReservation, selectedReservation, toggleReservationsSidebar } from '../../actions/reservationsActions';
import { userAuthPermissions } from '../../actions/usersActions';

import { _trans } from '../../actions/locoBOActions';

import moment from 'moment';

export default function FormComponent() {

    const { locosBO, loading: locosBOLoading, error: locosBOError } = useSelector(state => state.listLocosBO)

    const CreateReservationsSchema = Yup.object().shape({
        name: Yup.string().min(3, Errors.min({ name: _trans('Name', locosBO), number: "3" })).required(Errors.required({ name: _trans('Name', locosBO) }))
    })

    const dispatch = useDispatch();

    const [id, setId] = useState('');

    const [selectAll, setSelectAll] = useState(false);

    const [allCheckboxes, setCheckboxes] = useState([]);

    const [product, setProduct] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [departDate, setDepartDate] = useState('');
    const [adults, setAdults] = useState('');
    const [kids, setKids] = useState('');
    const [babies, setBabies] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [saveSuccess, setSaveSuccess] = useState(false);

    const reservationsToggleSidebar = useSelector(state => state.toggleReservationsSidebar);

    const reservationSelected = useSelector(state => state.selectedReservation);

    const reservationsSave = useSelector(state => state.reservationsSave);

    const [value, setValue] = useState(0); // integer state
    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = reservationsSave;

    useEffect(() => {
        if (reservationSelected.reservation) {
            setId(reservationSelected.reservation.id);
            setProduct(reservationSelected.reservation.product)
            setArrivalDate(moment(reservationSelected.reservation.arrival_date).format('DD/MM/YYYY'));
            setDepartDate(moment(reservationSelected.reservation.depart_date).format('DD/MM/YYYY'));
            setAdults(reservationSelected.reservation.adults);
            setKids(reservationSelected.reservation.kids);
            setBabies(reservationSelected.reservation.babies);
            setPhone(reservationSelected.reservation.phone);
            setEmail(reservationSelected.reservation.email);
            setMessage(reservationSelected.reservation.message);
        } else {
            setId('');
            setArrivalDate('');
            setDepartDate('');
            setAdults('');
            setKids('');
            setBabies('');
            setPhone('');
            setEmail('');
            setMessage('');
        }

        if (saveSuccess) {
            setSaveSuccess(false);
            dispatch(listReservations());
            toast.success(_trans('Reservation Saved', locosBO));
        }

    }, [reservationSelected, saveSuccess]);

    const closeFormSidebar = () => {
        dispatch(selectedReservation(null));
        dispatch(toggleReservationsSidebar(false));
    }

    const handleSubmit = (values) => {
        dispatch(saveReservation(values, (response) => {
            dispatch(userAuthPermissions());
            setSaveSuccess(true);
        }));
    }

    const selectAllHandler = () => {
        let checkboxes = allCheckboxes;
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i] = !selectAll;
        }
        setCheckboxes(checkboxes);
        setSelectAll(!selectAll);
    }


    return <React.Fragment>
        {loadingSave && <div></div>}
        {errorSave && <div>{errorSave}</div>}
        <div className={reservationsToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
                <div className="card-content pt-0">
                    <div className="card-header display-flex pb-2">
                        <h3 className="card-title reservation-title-label">{id === '' ? _trans('Create New Reservation', locosBO) : _trans('Update Reservation', locosBO)}</h3>
                        <div className="close close-icon" onClick={closeFormSidebar}>
                            <i className="material-icons">close</i>
                        </div>
                    </div>
                    <div className="divider"></div>

                    <Formik
                        initialValues={{
                            id: id ? id : '',
                            product: product ? product : '',
                            arrivalDate: arrivalDate ? arrivalDate : '',
                            departDate: departDate ? departDate : '',
                            adults: adults ? adults : '0',
                            babies: babies ? babies : '0',
                            kids: kids ? kids : '0',
                            phone: phone ? phone : '',
                            email: email ? email : '',
                            message: message ? message : '',
                        }}
                        validationSchema={CreateReservationsSchema}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            closeFormSidebar();
                            values = { ...values };
                            setSelectAll(false);
                            handleSubmit(values);
                            resetForm({ values: '' });
                        }}
                    >
                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                            <Form className="edit-reservation-item mb-5 mt-5">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <Field id="reservations_product" name="product" type="text" className={errors.product ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_product" className={product !== '' ? 'active' : ''}>{_trans('Product', locosBO)}</label>
                                        {errors.product && touched.product ? (
                                            <small className="errorTxt2"><div id="product-error" className="error">{errors.product}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="reservations_arrivalDate" name="arrivalDate" type="text" className={errors.arrivalDate ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_arrivalDate" className={arrivalDate !== '' ? 'active' : ''}>{_trans('Arrival Date', locosBO)}</label>
                                        {errors.arrivalDate && touched.arrivalDate ? (
                                            <small className="errorTxt2"><div id="arrivalDate-error" className="error">{errors.arrivalDate}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="reservations_departDate" name="departDate" type="text" className={errors.departDate ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_departDate" className={departDate !== '' ? 'active' : ''}>{_trans('Depart Date', locosBO)}</label>
                                        {errors.departDate && touched.departDate ? (
                                            <small className="errorTxt2"><div id="departDate-error" className="error">{errors.departDate}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="reservations_adults" name="adults" type="text" className={errors.adults ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_adults" className={adults !== '' ? 'active' : ''}>{_trans('Adults', locosBO)}</label>
                                        {errors.adults && touched.adults ? (
                                            <small className="errorTxt2"><div id="adults-error" className="error">{errors.adults}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="reservations_babies" name="babies" type="text" className={errors.babies ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_babies" className={babies !== '' ? 'active' : ''}>{_trans('Babies', locosBO)}</label>
                                        {errors.babies && touched.babies ? (
                                            <small className="errorTxt2"><div id="babies-error" className="error">{errors.babies}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="reservations_kids" name="kids" type="text" className={errors.kids ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_kids" className={kids !== '' ? 'active' : ''}>{_trans('Kids', locosBO)}</label>
                                        {errors.kids && touched.kids ? (
                                            <small className="errorTxt2"><div id="kids-error" className="error">{errors.kids}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="reservations_phone" name="phone" type="text" className={errors.phone ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_phone" className={phone !== '' ? 'active' : ''}>{_trans('Phone', locosBO)}</label>
                                        {errors.phone && touched.phone ? (
                                            <small className="errorTxt2"><div id="phone-error" className="error">{errors.phone}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="reservations_email" name="email" type="text" className={errors.email ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_email" className={email !== '' ? 'active' : ''}>{_trans('Email', locosBO)}</label>
                                        {errors.email && touched.email ? (
                                            <small className="errorTxt2"><div id="email-error" className="error">{errors.email}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="reservations_message" name="message" type="text" className={errors.message ? "error validate" : "validate"} />
                                        <label htmlFor="reservations_message" className={message !== '' ? 'active' : ''}>{_trans('Message', locosBO)}</label>
                                        {errors.message && touched.message ? (
                                            <small className="errorTxt2"><div id="message-error" className="error">{errors.message}</div></small>
                                        ) : null}
                                    </div>

                                </div>

                                <div className="card-action pl-0 pr-0 right-align">
                                    <button type="submit" className="btn-small waves-effect waves-light add-reservation" disabled={isSubmitting}>
                                        {!id ? (
                                            <span>{_trans('Save', locosBO)}</span>
                                        ) : (
                                            <span>{_trans('Edit', locosBO)}</span>
                                        )}
                                    </button>
                                </div>

                            </Form>
                        )}

                    </Formik>

                </div>
            </div>
        </div>
    </React.Fragment>
}