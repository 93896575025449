import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { listFiles, uploadFiles } from '../../actions/fileManagerActions';
import FileManagerProgressebar from './FileManagerProgressebar';
import axios from 'axios';
import Swal from 'sweetalert2';

import { _trans } from '../../actions/locoBOActions';

export default function FileManagerFiltre(props) {

    const { locosBO, loading: locosBOLoading, error: locosBOError } = useSelector(state => state.listLocosBO)

    const [uploadPercentage, setUploadPercentage] = useState(0);
    const {
        loading: loadingUpload,
        uploadData
    } = useSelector(state => state.uploadData);
    const dispatch = useDispatch();

    const filesList = useSelector(state => state.filesList);
    const { files,
        loading,
        error } = filesList;

    const [imageCount, setImageCount] = useState(0);
    const [documentCount, setDocumentCount] = useState(0);
    const [videoCount, setVideoCount] = useState(0);
    const [audioCount, setAudioCount] = useState(0);
    const [trashCount, setTrashCount] = useState(0);
    const [nontrashCount, setNonTrashCount] = useState(0);

    const multiFilesSelected = useSelector(state => state.selectedMultiFiles);

    useEffect(() => {

        // setTimeout(() => {
        // clear percentage
        if (!loadingUpload) {
            setUploadPercentage(0)
        }

        if (!loadingUpload && uploadData?.file) {
            dispatch(listFiles());
        }
        // }, 500);
        if (uploadData?.progress) {
            setUploadPercentage(uploadData.progress)
        }
    }, [loadingUpload, uploadData])

    useEffect(() => {
        if (files) {
            if (files['files']) {
                filesCounter();
            }
        }
    }, [files])

    const uploadFile = async e => {

        const formData = new FormData();

        let filecount = e.target.files.length;

        //formData.append('file', e.target.files[0]);

        formData.append('file', e.target.files);

        for (var x = 0; x < filecount; x++) {
            formData.append("file", e.target.files[x]);
        }

        try {

            dispatch(uploadFiles(formData))
        } catch (error) {
            console.log(error);
        }

    }

    const filesCounter = () => {

        let filesTrash = files['files'].filter(item => item.path && item.path.includes('/trash/'));
        setTrashCount(filesTrash.length);

        let filesImage = files['files'].filter(item => item.extension && !item.path.includes('/trash/') && (item.extension.includes('jpg') || item.extension.includes('jpeg') || item.extension.includes('png')));
        setImageCount(filesImage.length);

        let filesDocument = files['files'].filter(item => item.extension && !item.path.includes('/trash/') && (item.extension.includes('pdf') || item.extension.includes('doc') || item.extension.includes('docx')));
        setDocumentCount(filesDocument.length);

        let filesVideo = files['files'].filter(item => item.extension && !item.path.includes('/trash/') && (item.extension.includes('mp4') || item.extension.includes('avi') || item.extension.includes('flv') || item.extension.includes('mov')));
        setVideoCount(filesVideo.length);

        let filesAudio = files['files'].filter(item => item.extension && !item.path.includes('/trash/') && (item.extension.includes('mp3') || item.extension.includes('wav')));
        setAudioCount(filesAudio.length);

        let filesNonTrash = files['files'].filter(item => item.path && !item.path.includes('/trash/'));
        setNonTrashCount(filesNonTrash.length);

    }

    const archiveMultiFiles = () => {

        Swal.fire({
            title: _trans('Are you sure?', locosBO),
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: _trans('Cancel', locosBO),
            confirmButtonText: _trans('Delete', locosBO)
        }).then((result) => {
            if (result.isConfirmed) {

                axios.patch("/api/files_manager/multi", {
                    selection: multiFilesSelected
                })
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(listFiles());
                            Swal.fire(
                                _trans('Delete', locosBO),
                                _trans('File successfully deleted', locosBO),
                                'success'
                            )
                        } else {
                            console.log('error');
                        }
                    });
            }
        })

    }

    return <React.Fragment>
        <div className="sidebar-left">
            {/* <!--left sidebar of file manager start --> */}
            <div className="app-file-sidebar display-flex">
                {/* <!-- App File sidebar - Left section Starts --> */}
                <div className="app-file-sidebar-left">

                    <div className="add-new-file mt-0">
                        <label htmlFor="mediaFile" className="add-file-btn btn btn-block waves-effect waves-light mb-10">
                            <i className="material-icons">add</i>
                            <span>{_trans('Add File', locosBO)}</span>
                        </label>

                        <div className="getfileInput">
                            <input type="file" id="mediaFile" onChange={uploadFile} onClick={e => (e.target.value = null)} multiple />
                        </div>
                    </div>

                    <FileManagerProgressebar percentage={uploadPercentage} />

                    <div className="app-file-sidebar-content">

                        {/* <!-- App File Left Sidebar - Drive Content Starts --> */}
                        <span className="app-file-label">{_trans('My Drive', locosBO)}</span>
                        <div className="collection file-manager-drive mt-3">
                            <Link to="/files-manager" className="collection-item file-item-action">
                                <div className="fonticon-wrap display-inline mr-3">
                                    <i className="material-icons">folder_open</i>
                                </div>
                                <span>All Files</span>
                                <span className="chip red lighten-5 float-right red-text">{nontrashCount}</span>
                            </Link>
                            <a href="#" className="collection-item file-item-action" onClick={(e) => archiveMultiFiles()}>
                                <div className="fonticon-wrap display-inline mr-3">
                                    <i className="material-icons">delete</i>
                                </div>
                                <span>{_trans('Delete selection', locosBO)}</span>
                            </a>
                            <a href="#" className="collection-item file-item-action">
                                <div className="fonticon-wrap display-inline mr-3">
                                    <i className="material-icons">star_border</i>
                                </div>
                                <span>{_trans('Important', locosBO)}</span>
                            </a>
                            <Link to="/files-manager/trash" className="collection-item file-item-action">
                                <div className="fonticon-wrap display-inline mr-3">
                                    <i className="material-icons">delete_forever</i>
                                </div>
                                <span>{_trans('Trash', locosBO)}</span>
                                <span className="chip red lighten-5 float-right red-text">{trashCount}</span>
                            </Link>
                        </div>
                        {/* <!-- App File Left Sidebar - Drive Content Ends --> */}

                        {/* <!-- App File Left Sidebar - Labels Content Starts --> */}
                        <span className="app-file-label">{_trans('Labels', locosBO)}</span>
                        <div className="collection file-manager-drive mt-3">
                            <Link to="/files-manager/images" className="collection-item file-item-action">
                                <div className="fonticon-wrap display-inline mr-3">
                                    <i className="material-icons">filter</i>
                                </div>
                                <span> {_trans('Images', locosBO)}</span>
                                <span className="chip red lighten-5 float-right red-text">{imageCount}</span>
                            </Link>
                            <Link to="/files-manager/documents" className="collection-item file-item-action">
                                <div className="fonticon-wrap display-inline mr-3">
                                    <i className="material-icons">content_paste</i>
                                </div>
                                <span> {_trans('Documents', locosBO)}</span>
                                <span className="chip red lighten-5 float-right red-text">{documentCount}</span>
                            </Link>
                            <Link to="/files-manager/videos" className="collection-item file-item-action">
                                <div className="fonticon-wrap display-inline mr-3">
                                    <i className="material-icons">ondemand_video</i>
                                </div>
                                <span> {_trans('Videos', locosBO)}</span>
                                <span className="chip red lighten-5 float-right red-text">{videoCount}</span>
                            </Link>
                            <Link to="/files-manager/audio" className="collection-item file-item-action">
                                <div className="fonticon-wrap display-inline mr-3">
                                    <i className="material-icons">music_note</i>
                                </div>
                                <span> {_trans('Audio', locosBO)}</span>
                                <span className="chip red lighten-5 float-right red-text">{audioCount}</span>
                            </Link>
                        </div>
                        {/* <!-- App File Left Sidebar - Labels Content Ends --> */}

                    </div>
                </div>
            </div>
            {/* <!--left sidebar of file manager start --> */}
        </div>
    </React.Fragment>
}
