import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose
} from 'redux';
import thunk from 'redux-thunk';
import {
    locationReducer
} from './reducers/locationReducer';
import {
    usersListReducer,
    usersSaveReducer,
    usersDeleteReducer,
    selectedUserReducer,
    toggleUsersSidebarReducer,
    userAuthPermissionsReducer
} from './reducers/usersReducers';
import {
    rolesListReducer,
    rolesSaveReducer,
    rolesDeleteReducer,
    selectedRoleReducer,
    toggleRolesSidebarReducer
} from './reducers/rolesReducer';
import {
    permissionsListReducer,
    permissionsSaveReducer,
    permissionsDeleteReducer,
    selectedPermissionsReducer,
    togglePermissionsSidebarReducer
} from './reducers/permissinsReducer';
import {
    filesListReducer,
    selectedFileReducer,
    selectedMultiFilesReducer,
    toggleFilesManagerModalReducer,
    filesManagerIsGalleryReducer,
    uploadFilesReducer
} from './reducers/filesManagerReducers';
import {
    postTypeListReducer,
    postTypeSaveReducer,
    postTypeDeleteReducer,
    selectedPostTypeReducer,
    togglePostTypeSidebarReducer,
    postTypeRestoreReducer,
    destroyPostTypeReducer,
    listTrashedPostTypeReducer
} from './reducers/postTypeReducers';
import {
    postsListReducer,
    listMenuPostsReducer,
    postsSaveReducer,
    postsDeleteReducer,
    selectedPostReducer,
    postsLangReducer,
    destroyPostReducer,
    postRestoreReducer,
    setSelectedPostCategorieItemsReducer
} from './reducers/postsReducers';
import {
    postTemplatesListReducer,
    selectedPostTemplateReducer,
    postTemplatesSaveReducer,
    postTemplatesDeleteReducer,
    postTemplatesRestoreReducer,
    destroyPostTempateReducer
} from './reducers/postTemplatesReducer';
import {
    authReducer,
    isLoggedInReducer,
    forgotPasswordReducer,
    verifyResetPasswordLinkReducer,
    resetPasswordReducer
} from './reducers/authReducers';
import {
    categoriesListReducer,
    selectedCategoryReducer,
    toggleCategoriesSidebarReducer,
    categoryDeleteReducer,
    categorySaveReducer,
    findCategoryByLangReducer,
    postTypesCategoriesReducer,
    categoryRestoreReducer,
    destroyCategoryReducer
} from './reducers/categoriesReducers';
import {
    languagesListReducer,
    languagesSaveReducer,
    languagesDeleteReducer,
    selectedLanguageReducer,
    toggleLanguagesSidebarReducer,
    currentLanguageReducer,
    languagesRestoreReducer,
    destroyLanguageReducer
} from './reducers/LanguagesReducers';
import {
    authorizdAppsListReducer,
    authorizdAppsSaveReducer,
    authorizdAppsDeleteReducer,
    selectedAuthorizedAppReducer,
    toggleAuthorizedAppsSidebarReducer,
    currentAuthorizedAppReducer,
    authorizdAppsRestoreReducer,
    destroyAuthorizedAppReducer
} from './reducers/AuthorizedAppsReducers';
import {
    menusListReducer,
    selectedMenuReducer,
    toggleMenusSidebarReducer,
    menuDeleteReducer,
    menuSaveReducer
} from './reducers/menusReducers';
import {
    setMenuItemsReducer,
    saveMenuItemReducer,
    MenuItemsListReducer,
    selectedMenuIdReducer,
    selectedMenuLangIdReducer
} from './reducers/menuItemsReducers';
import {
    setCategoryItemsReducer,
    saveCategoryItemReducer,
    categoryItemsListReducer,
    selectedCategoryIdReducer,
    selectedCategoryLangIdReducer,
    findCategoryItemReducer,
    deleteCategoryItemReducer
} from './reducers/categoryItemsReducer';
import {
    setSideBarItemsReducer
} from './reducers/SideBarReducer';
import {
    listLocosReducer,
    getLocoTranslateReducer,
    patchLocoReducer
} from './reducers/locosReducers';
import {
    listLocosBOReducer,
    getLocoBOTranslateReducer,
    patchLocoBOReducer
} from './reducers/locosBOReducers';
import {
    reservationsListReducer,
    reservationsSaveReducer,
    reservationsDeleteReducer,
    selectedReservationReducer,
    toggleReservationsSidebarReducer
} from './reducers/reservationsReducer';
import {
    contactsListReducer,
    contactsSaveReducer,
    contactsDeleteReducer,
    selectedContactReducer,
    toggleContactsSidebarReducer
} from './reducers/contactsReducer';

const initialState = {};
const reducer = combineReducers({

    location: locationReducer,

    //users 
    usersList: usersListReducer,
    usersSave: usersSaveReducer,
    usersDelete: usersDeleteReducer,
    selectedUser: selectedUserReducer,
    toggleUsersSidebar: toggleUsersSidebarReducer,
    userAuthPermissions: userAuthPermissionsReducer,

    //roles
    rolesList: rolesListReducer,
    rolesSave: rolesSaveReducer,
    rolesDelete: rolesDeleteReducer,
    selectedRole: selectedRoleReducer,
    toggleRolesSidebar: toggleRolesSidebarReducer,

    //permissions
    permissionsList: permissionsListReducer,
    permissionsSave: permissionsSaveReducer,
    permissionsDelete: permissionsDeleteReducer,
    selectedPermission: selectedPermissionsReducer,
    togglepermissionsSidebar: togglePermissionsSidebarReducer,

    //fileManager
    filesList: filesListReducer,
    selectedFile: selectedFileReducer,
    selectedMultiFiles: selectedMultiFilesReducer,
    toggleFilesManagerModal: toggleFilesManagerModalReducer,
    filesManagerIsGallery: filesManagerIsGalleryReducer,
    uploadData: uploadFilesReducer,

    //Post Type
    postTypeList: postTypeListReducer,
    postTypeSave: postTypeSaveReducer,
    postTypeDelete: postTypeDeleteReducer,
    selectedPostType: selectedPostTypeReducer,
    togglepostTypeSidebar: togglePostTypeSidebarReducer,
    restoredPostType: postTypeRestoreReducer,
    destroyedPostType: destroyPostTypeReducer,
    postTypeTrashedList: listTrashedPostTypeReducer,


    //Posts
    postsList: postsListReducer,
    menuPosts: listMenuPostsReducer,
    postsSave: postsSaveReducer,
    postsDelete: postsDeleteReducer,
    selectedPost: selectedPostReducer,
    postsLang: postsLangReducer,
    restoredPost: postRestoreReducer,
    destroyedPost: destroyPostReducer,
    selectedPostCategoriesItems: setSelectedPostCategorieItemsReducer,

    //postTemplates
    postTemplatesList: postTemplatesListReducer,
    postTemplatesSave: postTemplatesSaveReducer,
    postTemplatesDelete: postTemplatesDeleteReducer,
    selectedPostTemplate: selectedPostTemplateReducer,
    restoredPostTemplate: postTemplatesRestoreReducer,
    destroyedPostTemplate: destroyPostTempateReducer,

    // auth 
    auth: authReducer,
    login: isLoggedInReducer,
    forgotPassword: forgotPasswordReducer,
    verifyResetPasswordLink: verifyResetPasswordLinkReducer,
    resetPassword: resetPasswordReducer,

    // categories
    categories: categoriesListReducer,
    selectedCategory: selectedCategoryReducer,
    toggleCategoriesSidebar: toggleCategoriesSidebarReducer,
    categoryDelete: categoryDeleteReducer,
    categorySave: categorySaveReducer,
    categoryLang: findCategoryByLangReducer,
    postTypesCategories: postTypesCategoriesReducer,
    restoredCategory: categoryRestoreReducer,
    destroyedCategory: destroyCategoryReducer,

    //Languages 
    languagesList: languagesListReducer,
    languagesSave: languagesSaveReducer,
    languagesDelete: languagesDeleteReducer,
    selectedLanguage: selectedLanguageReducer,
    toggleLanguagesSidebar: toggleLanguagesSidebarReducer,
    currentLang: currentLanguageReducer,
    restoredLang: languagesRestoreReducer,
    destroyedLang: destroyLanguageReducer,

    //Ahuthorized apps 
    authorizedAppsList: authorizdAppsListReducer,
    authorizedAppsSave: authorizdAppsSaveReducer,
    authorizedAppsDelete: authorizdAppsDeleteReducer,
    selectedAhutorizedApp: selectedAuthorizedAppReducer,
    toggleAhutorizedAppsSidebar: toggleAuthorizedAppsSidebarReducer,
    currenteAhutorizedApp: currentAuthorizedAppReducer,
    restoredeAhutorizedApp: authorizdAppsRestoreReducer,
    destroyedeAhutorizedApp: destroyAuthorizedAppReducer,

    // menus
    menus: menusListReducer,
    selectedMenu: selectedMenuReducer,
    toggleMenusSidebar: toggleMenusSidebarReducer,
    menuDelete: menuDeleteReducer,
    menuSave: menuSaveReducer,
    // menu items 
    menuItems: setMenuItemsReducer,
    menuItemsList: MenuItemsListReducer,
    menuItemsSave: saveMenuItemReducer,
    menuSelected: selectedMenuIdReducer,
    menuLangSelected: selectedMenuLangIdReducer,
    // category items 
    categoryItems: setCategoryItemsReducer,
    categoryItemsList: categoryItemsListReducer,
    categoryItemsSave: saveCategoryItemReducer,
    categorySelected: selectedCategoryIdReducer,
    categoryLangSelected: selectedCategoryLangIdReducer,
    categoryItem: findCategoryItemReducer,
    deletedCategoryItem: deleteCategoryItemReducer,

    // sidebar menu data 
    sideBarItems: setSideBarItemsReducer,

    // listLocosReducer
    listLocos: listLocosReducer,
    locosTranslate: getLocoTranslateReducer,
    patchLoco: patchLocoReducer,

    // listLocosBOReducer
    listLocosBO: listLocosBOReducer,
    locosBOTranslate: getLocoBOTranslateReducer,
    patchLocoBO: patchLocoBOReducer,

    //Reservations
    reservationsList: reservationsListReducer,
    reservationsSave: reservationsSaveReducer,
    reservationsDelete: reservationsDeleteReducer,
    selectedReservation: selectedReservationReducer,
    toggleReservationsSidebar: toggleReservationsSidebarReducer,

    //contacts
    contactsList: contactsListReducer,
    contactsSave: contactsSaveReducer,
    contactsDelete: contactsDeleteReducer,
    selectedContact: selectedContactReducer,
    toggleContactsSidebar: toggleContactsSidebarReducer,

})


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhancer(applyMiddleware(thunk)));

export default store;