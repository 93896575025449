export const FILES_LIST_REQUEST = 'FILES_LIST_REQUEST';
export const FILES_LIST_SUCCESS = 'FILES_LIST_SUCCESS';
export const FILES_LIST_FAIL = 'FILES_LIST_FAIL';

export const SELECTED_FILE = 'SELECTED_FILE';
export const SELECTED_MULTI_FILES = 'SELECTED_MULTI_FILES';
export const FILEMANAGER_MODAL_IS_TOGGLED = 'FILEMANAGER_MODAL_IS_TOGGLED';
export const FILEMANAGER_MODAL_IS_GALLERY = 'FILEMANAGER_MODAL_IS_GALLERY';

export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAIL = 'UPLOAD_FILES_FAIL';
export const UPLOAD_FILES_IN_PROGRESS = 'UPLOAD_FILES_IN_PROGRESS';