import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listReservations, saveReservation, deleteReservation, selectedReservation, toggleReservationsSidebar } from '../../actions/reservationsActions';
import Swal from 'sweetalert2';
import useHasPermission from '../../hooks/useHasPermission'

import { _trans } from '../../actions/locoBOActions';

import moment from 'moment';

export default function ReservationsDatatable() {

    const { locosBO, loading: locosBOLoading, error: locosBOError } = useSelector(state => state.listLocosBO)

    const dispatch = useDispatch();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const reservationsList = useSelector(state => state.reservationsList);
    const { reservations,
        loading,
        error } = reservationsList;
    const canUpdate = useHasPermission("/reservations", "update");
    const canDelete = useHasPermission("/reservations", "delete");
    const reservationDelete = useSelector(state => state.reservationsDelete);
    const { loading: loadingDelete,
        success: successDelete,
        error: errorDelete } = reservationDelete;

    const reservationsSave = useSelector(state => state.reservationsSave);
    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = reservationsSave;

    const columns = [
        {
            name: _trans('Product', locosBO),
            selector: 'product',
            sortable: false,
        },
        {
            name: _trans('Arrival_date', locosBO),
            selector: 'arrival_date',
            sortable: true,
            format: (row) => moment(row.arrival_date).format('DD/MM/YYYY'),
        },
        {
            name: _trans('Depart_date', locosBO),
            selector: 'depart_date',
            sortable: false,
            format: (row) => moment(row.depart_date).format('DD/MM/YYYY'),
        },
        {
            name: _trans('Email', locosBO),
            selector: 'email',
            sortable: false,
        },
        {
            name: _trans('Phone', locosBO),
            selector: 'phone',
            sortable: false,
        },
        {
            name: _trans('Email', locosBO),
            selector: 'email',
            sortable: false,
        },
        {
            cell: (reservation) => canUpdate ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editReservationsHandler(reservation); }}>
                <i className="material-icons">edit</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (reservation) => canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deleteReservationsHandler(reservation); }}>
                <i className="material-icons">delete</i>
            </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];

    useEffect(() => {

        setFiltredData(reservations);

        if (deleteSuccess) {
            setDeleteSuccess(false);
            dispatch(listReservations());
        }

    }, [successSave, deleteSuccess, reservations]);


    const editReservationsHandler = (reservationData) => {
        dispatch(selectedReservation(reservationData));
        dispatch(toggleReservationsSidebar(true));
    }

    const deleteReservationsHandler = (reservationData) => {
        Swal.fire({
            title: _trans('Are you sure?', locosBO),
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33',
            cancelButtonText: _trans('Cancel', locosBO),
            confirmButtonText: _trans('Delete', locosBO)
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteReservation(reservationData, (response) => {
                    setDeleteSuccess(true);
                    Swal.fire(
                        _trans('Delete', locosBO),
                        _trans('Reservation successfully deleted', locosBO),
                        'success'
                    )
                }));

            }
        })
    }

    const dataFiltre = (e) => {
        let textFiltre = e.target.value;
        let data = reservations.filter(item => item.name && item.name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }

    return <React.Fragment>
        {loading || loadingDelete && <div></div>}
        {error && <div>{error}</div>}
        {errorDelete && <div>{errorDelete}</div>}
        <div className="content-area content-right">
            <div className="app-wrapper">
                <div className="datatable-search">
                    <i className="material-icons mr-2 search-icon">search</i>
                    <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                </div>
                <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{ noRowsPerPage: true }}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
}

