import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReservationsCount from './ReservationsCount';

import { _trans } from '../../actions/locoBOActions';

export default function ReservationsFiltre( props ) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    return (
        <React.Fragment>
            {/* <!-- Sidebar Area Starts --> */}
            <div className="sidebar-left sidebar-fixed">
            <div className="sidebar">
            <div className="sidebar-content">
            <div className="sidebar-header">
                <div className="sidebar-details">
                    <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">group</i> {_trans('Reservations',locosBO)}
                </h5>
                <div className="mt-10 pt-2">
                    <p className="m-0 subtitle font-weight-700">{_trans('Count Reservations',locosBO)}</p>
                    <ReservationsCount />
                </div>
                </div>
            </div>
            <div id="sidebar-list" className="sidebar-menu list-group position-relative ps ps--active-y">
            </div>
            
            </div>
            </div>
            </div>
            {/* <!-- Sidebar Area Ends --> */}
        </React.Fragment>
    );
}